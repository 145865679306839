import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthManagement } from '../security/auth-management';

@Injectable({
  providedIn: 'root'
})
export class HttpClientService {
  constructor(private http: HttpClient, private auth: AuthManagement) {}

    getNews<T>(url, params = {}, tokenAuth: boolean = false): Observable<T> {
        const token = this.auth.getToken();
        const options = {
            params: {},
            headers: {},
        };
        if (token && !tokenAuth) {
            options.headers = {
                'Authorization': 'Bearer' + token.token,
            };
        }

        options.params = params;
        return this.http.get<T>(
            url,
            options
        );
    }
  get<T>(url, params = {}, tokenAuth: boolean = false): Observable<T> {
    const token = this.auth.getToken();
    const options = {
      params: {},
      headers: {},
    };
    if (token && !tokenAuth) {
        options.headers = {
            'Authorization': 'Bearer' + token.token,
        };
    }

    options.params = params;
    return this.http.get<T>(
      url,
      options
    );
  }

  post<T>(url, params): Observable<T> {
    const token = this.auth.getToken();

    const options = {
      headers: {},
    };
    if (token) {
      options.headers = {
        'Authorization': 'Bearer ' + token.token,
      };
    }

    return this.http.post<T>(
      url,
      params,
      options,
    );
  }

  put<T>(url, params): Observable<T> {
    const token = this.auth.getToken();

    const options = {
      headers: {},
    };

    if (token) {
      options.headers = {
        'Authorization': 'Bearer ' + token.token,
      };
    }

    return this.http.put<T>(
      url,
      params,
      options,
    );
  }

    delete<T>(url , params): Observable<T> {
        const token = this.auth.getToken();

        const options = {
            headers: {},
            body: []
        };

        if (token) {
            options.headers = {
                'Authorization': 'Bearer ' + token.token,
            };
        }
        if (params) {
          options.body = params;
        }

        return this.http.delete<T>(
            url,
            options,
        );
    }
}
