import { Injectable } from '@angular/core';
import { LoginDomain, Token } from '../security/auth-management';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SecurityRepository {
  constructor(private http: HttpClient) {}

  login(login: LoginDomain) {
    let url = environment.api_uri + '/security/login';

    return this.http.post<Token>(url, login);
  }
  // getCurrentToken(token){
  //   let url = environment.api_uri + '/security/token/'+token;
  //   return this.http.post<Token>(url,token);
  // }

  sendPassword(email) {
    let url = environment.api_uri + '/security/reset/password';

    return this.http.post<Number>(url, email);
  }

  changePassword(password, token) {
    let url = environment.api_uri + '/security/reset/password/' + token;
    return this.http.post<Number>(url, password);
  }

  affectOrganization(data, token) {
    const url = environment.api_uri + '/security/affect/organization';
    const headers = { 'Authorization': 'Bearer ' + token};
    return this.http.post(url, data, {headers});
  }
}
