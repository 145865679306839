import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import { AuthManagement } from './security/auth-management';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'SmartFlo';

  constructor(private router: Router, private auth: AuthManagement) { }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }

      const anonyUrls = ['/auth/login', '/auth/logout', '/auth/reset/password'];
      let doLogin = true;
      anonyUrls.map( url => {
        if (evt.url.includes(url)) {
          doLogin = false;
        }
      });
      if (doLogin) {
        if (!this.auth.isLogged()) {
          this.router.navigate(['/auth/login']);
        }
      }

      // window.scrollTo(0, 0);
    });
  }
}
