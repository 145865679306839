import {BaseRepository} from '@repository/base.repository';
import {HttpClientService} from '../service/http-client.service';
import {environment} from '../../environments/environment';
import {Injectable} from '@angular/core';
import {Organization} from '../model/organization';
import {Observable} from 'rxjs';
import {Token} from '../security/auth-management';

@Injectable({
    providedIn: 'root'
})

export class OrganizationRepository extends BaseRepository<Organization> {
    constructor(http: HttpClientService) {
        super(http);
    }

    editOrganization(id, data) {
        const url = this.getBaseUrl() + id + '/edit/';
        return this.http.post(url, data);
    }

    getBaseUrl() {
        return environment.api_uri + '/organization/';
    }

    setOrganization(data): Observable<Token> {
        const url = this.getBaseUrl() + 'set/token/organization';
        return this.http.post(url, data);
    }

    getCssStyle(id): Observable<any> {
        const url = this.getBaseUrl() + id + '/css/style';
        return this.http.get(url);
    }
}
