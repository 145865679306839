import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthManagement } from './security/auth-management';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
    constructor(private _router: Router, private _session: AuthManagement) {
    }
    
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      let isAdmin = this._session.isLoggedAsAdmin();
      if (isAdmin !== true) {
        this._router.navigate(['dashboard']);
    }
        return this._session.isLoggedAsAdmin();
    }
}
