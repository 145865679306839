import {Injectable} from '@angular/core';
import { AuthManagement } from 'src/app/security/auth-management';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  short_label?: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

const MENUITEMS = [
  {
    label: 'Plateforme',
    main: [
      {
        state: 'dashboard',
        short_label: 'D',
        name: 'Tableau de Bord',
        type: 'link',
        icon: 'feather icon-activity',
      }
    ]
  },
  {
    label: 'Interventions',
    main: [
      {
        state: 'tickets',
        short_label: 'B',
        name: 'Liste des interventions',
        type: 'link',
        icon: 'feather icon-briefcase',
      },
      {
        state: 'tickets-informatique',
        short_label: 'B',
        name: 'Service Informatique',
        type: 'link',
        icon: 'feather icon-briefcase',
      },
      {
        main_state: 'context',
        state: 'create-context',
        short_label: 'Edit',
        name: 'Règles d\'affectation',
        type: 'link',
        icon: 'feather icon-book',
      },
      {
        main_state: 'context',
        state: 'list',
        short_label: 'Edit',
        name: 'Règles d\'escalade',
        type: 'link',
        icon: 'feather icon-list',
      },
      {
        main_state: 'channel-providers',
        state: 'list-providers',
        short_label: 'Flux',
        name: 'Flux externes',
        icon: 'feather icon-server',
        type: 'link',
      },
    ]
  },
  {
    label: 'Serviciel / Espaces',
    main: [
      {
        state: 'rooms',
        short_label: 'U',
        name: 'Gestion des espaces',
        type: 'link',
        icon: 'feather icon-codepen'
      },
      {
        main_state: 'room-types',
        state: 'list-types',
        short_label: 'SC',
        name: 'Types d\'espace',
        type: 'link',
        icon: 'feather icon-server',
      },
      {
        main_state: 'equipments',
        state: 'list-equipments',
        short_label: 'SL',
        name: 'Gestion des services',
        icon: 'feather icon-server',
        type: 'link',
      }
      
     
    ]
  },
  {
    label: 'Fil d actualités',
    main: [
      {
        main_state: 'news',
        state: 'list-news',
        short_label: 'SL',
        name: 'Gestion des actualités',
        icon: 'fa fa-newspaper-o',
        type: 'link',
      },
      {
        main_state: 'news',
        state: 'list-profile',
        short_label: 'SL',
        name: 'Gestion des profiles',
        icon: 'fa fa-user',
        type: 'link',
      }
    ]
  },
  {
    label: 'Services / Documents',
    main: [
      {
        main_state: 'document',
        state: 'list-document',
        short_label: 'SL',
        name: 'Gestion des documents',
        icon: 'fa fa-book',
        type: 'link',
      }
    ]
  },
  {
    label: 'Gestion des Equipements',
    main: [
      {
        main_state: 'qrcode',
        state: 'list-qrcode',
        short_label: 'SL',
        name: 'Gestion des qrcodes',
        icon: 'fa fa-book',
        type: 'link',
      }
    ]
  },
  {
    label: 'Services / Paramétrage',
    main: [
      {
        main_state: 'services',
        state: 'list-services',
        short_label: 'SL',
        name: 'Gestion des services',
        icon: 'feather icon-server',
        type: 'link',
      }
    ]
  },
  {
    label: 'Cartographie',
    main: [
      {
        main_state: 'zones',
        state: 'list-zones',
        short_label: 'SL',
        name: 'Gestion des zones',
        icon: 'fa fa-list',
        type: 'link',
      }
    ]
  },
  {
    label: 'Plateforme / Sites',
    main: [
      {
        main_state: 'organization',
        state: 'list-organization',
        short_label: 'SL',
        name: 'Organisations',
        icon: 'fa fa-list',
        type: 'link',
      }
    ]
  },
   {
    label: 'Configuration',
    main: [
      {
        state: 'users',
        short_label: 'U',
        name: 'Gestion des utilisateurs',
        type: 'link',
        icon: 'feather icon-users'
      },
      {
        main_state: 'floors',
        state: 'list',
        short_label: 'SL',
        name: 'Gestion des étages',
        icon: 'feather icon-server',
        type: 'link',
      },
    ]
  },
];

const USERITEMS = [

  {
    label: 'Gestion',
    main: [
      {
        state: 'dashboard',
        short_label: 'D',
        name: 'Dashboard',
        type: 'link',
        icon: 'feather icon-activity',
      },
      {
        state: 'tickets',
        short_label: 'B',
        name: 'Liste des interventions',
        type: 'link',
        icon: 'feather icon-briefcase',
      }
    ]
  },
  {
    label: 'Actualité',
    main: [
      {
        main_state: 'news',
        state: 'list-news',
        short_label: 'SL',
        name: 'Gestion des actualités',
        icon: 'fa fa-newspaper-o',
        type: 'link',
      },
      {
        main_state: 'news',
        state: 'list-profile',
        short_label: 'SL',
        name: 'Gestion des profiles',
        icon: 'fa fa-user',
        type: 'link',
      }
    ]
  },
  {
    label: 'Documents',
    main: [
      {
        main_state: 'document',
        state: 'list-document',
        short_label: 'SL',
        name: 'Gestion des documents',
        icon: 'fa fa-book',
        type: 'link',
      }
    ]
  },
];

@Injectable()
export class MenuItems {
  constructor(private authManager :AuthManagement){

  }
  getAll(): Menu[] {
    if(this.authManager.isLoggedAsAdmin()){
      return MENUITEMS;
    } else {
      return USERITEMS;
    }

  }
}
