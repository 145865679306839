import { HttpClientService } from '../service/http-client.service';
import { Observable } from 'rxjs';
import { UserAssignment } from '../model/user';

export abstract class BaseRepository<T> {
  constructor(protected http: HttpClientService) {}

  /**
   * something like environment.api_uri + '/entities/';
   */
  abstract getBaseUrl(): string;


  getAll(): Observable<T[]> {
    const url = this.getBaseUrl();
    return this.http.get<T[]>(url);
  }
  search(criteria): Observable<T[]> {
    const url = this.getBaseUrl();

    return this.http.get<T[]>(url, criteria);
  }

  create(entity: any): Observable<T> {
    const url = this.getBaseUrl();

    return this.http.post<T>(url, entity);
  }

  get(id): Observable<T> {
    const url = this.getBaseUrl() + id;
    return this.http.get<T>(url);
  }



  getUserAssignments(id): Observable<UserAssignment[]> {
    const url = this.getBaseUrl() + id + '/user-assignments';
    return this.http.get<UserAssignment[]>(url);
  }

  update(entity): Observable<T> {
    const url = this.getBaseUrl() + entity.id;

    const data = Object.assign({}, entity);
    delete data.id;

    return this.http.put(url, data);
  }

  delete(entity): Observable<T> {
      const url = this.getBaseUrl() + entity.id;

      return this.http.delete(url, {});
  }

}
